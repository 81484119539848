import jsx from '../lib/jsx'
import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const inputs = document.querySelectorAll('div.thumbnail-selection')
	for (let a = 0; a < inputs.length; a++) {
		new ThumbnailSelect(inputs[a])
	}
})

export default function ThumbnailSelect(self) {
	const associatedElementClass = self.getAttribute('data-associated')
	const fileSelection = document.querySelector('div.file-selection.' + associatedElementClass)
	if (!fileSelection) {
		return null
	}

	Object.defineProperties(self, Object.getOwnPropertyDescriptors(ThumbnailSelect.prototype))

	const canvas = <canvas />
	self.context = canvas.getContext('2d')
	self.fileInput = self.querySelector('input')
	self.placeholder = self.querySelector('span')

	// Video element.
	self.frameSelector = (
		<div class='frame-selector'>
			<div class='video-wrapper'>
				<video
					controls
					muted
					onloadedmetadata={self.onVideoLoad.bind(self)}
					preload
					ref={el => self.videoElement = el} />
			</div>
		</div>
	)

	// When changing file.
	fileSelection.addEventListener('delete', function () {
		self.setFrameSelector(null)
	})

	const videoSelectionInput = fileSelection.querySelector('input[type=file]')
	videoSelectionInput.addEventListener('change', function (event) {
		self.setFrameSelector(event.target.files[0])
	})

	if (fileSelection.currentFile) {
		self.setFrameSelector(fileSelection.currentFile)
	}

	// On submit.
	if (self.fileInput.form) {
		self.fileInput.form.addEventListener('submit', function (event) {
			const isActive = self.videoElement.offsetWidth > 0
			if (isActive) {
				self.fileInput.value = self.getTimestamp()
			}
		})
	}
}

ThumbnailSelect.prototype = {
	setFrameSelector(videoFile) {
		// Always remoke the previous url. *always*
		if (this.videoElement.src) {
			URL.revokeObjectURL(this.videoElement.src)
		}

		if (!videoFile) {
			this.classList.remove('has-video')
			this.frameSelector.remove()
			return
		}

		if (videoFile instanceof File) {
			const url = URL.createObjectURL(videoFile)
			this.videoElement.src = url
			this.videoElement.type = videoFile.type
		}
		else if (typeof videoFile === 'string') {
			this.videoElement.src = videoFile
			this.videoElement.type = 'video/mp4'
		}

		this.appendChild(this.frameSelector)
		this.classList.add('has-video')
	},

	getTimestamp() {
		const doubleDigit = val => val <= 9 ? `0${val}` : val
		const timestamp = this.videoElement.currentTime
		const hours = doubleDigit(Math.floor(timestamp / 60 / 60))
		const minutes = doubleDigit(Math.floor(timestamp / 60) % 60)
		const seconds = doubleDigit(Math.floor(timestamp) % 60)
		const miliseconds = Math.round((timestamp - Math.floor(timestamp)) * 100)
		return `${hours}:${minutes}:${seconds}.${miliseconds}`
	},

	onVideoLoad(event) {
		this.classList.add('ready')
	}
}