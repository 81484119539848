import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const fileBrowsers = document.querySelectorAll('div.file-browser')
	for (let a = 0; a < fileBrowsers.length; a++) {
		new FileBrowser(fileBrowsers[a])
	}
})

export default function FileBrowser(element) {
	// Filelist logic.
	const existingInput = element.querySelector('input[type=hidden]')
	const filelistItems = element.querySelectorAll('table tr')

	for (let a = 0; a < filelistItems.length; a++) {
		filelistItems[a].addEventListener('click', function (event) {
			for (let b = 0; b < filelistItems.length; b++) {
				if (filelistItems[b] === this) {
					element.currentFile = existingInput.value = this.children[1].textContent

					if (!this.classList.contains('active')) {
						this.classList.add('active')
						dispatchEvent(element, 'change')
					}
				}
				else {
					filelistItems[b].classList.remove('active')
				}
			}
		})
	}
}
