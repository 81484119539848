import { elementInView } from './lib/helpers'

/**
 * This will load (and fade in) higher resolution images
 * with elements that have the `img-src` attribute.
 * TODO: React on and to scrolling.
 */
addEventListener('DOMContentLoaded', (event) => {
    // Find all elements with `img-src` attributes.
    let elements = [...document.querySelectorAll('*[img-src]')]

    function loadForElement(element) {
        // Create the ui-image element and insert it.
        let uiImage = document.createElement('ui-image')
        const firstNode = element.childNodes[0]
        if (firstNode) {
            element.insertBefore(uiImage, firstNode)
        }
        else {
            element.appendChild(uiImage)
        }

        // Load the image.
        const src = element.getAttribute('img-src')
        let image = new Image()
        image.onload = function () {
            uiImage.style.backgroundImage = `url("${src}")`
            element.removeAttribute('img-src')
        }
        image.src = src
    }

    function scroll(event) {
        for (let a = elements.length - 1; a >= 0; a--) {
            if (elementInView(elements[a])) {
                loadForElement(elements[a])
                elements.splice(a, 1)
            }
        }
    }

    addEventListener('scroll', scroll)
    
    scroll()
})