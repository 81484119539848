import jsx from '../lib/jsx'
import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const selects = document.querySelectorAll('input[type=text].keywords')
	for (const select of selects) {
		new KeywordsField(select)
	}
})

/**
 * Keywords field is an input text field that automatically detects separate words.
 * Use the `.keywords` property to get the array of keywords.
 * Use `.value` to get all keywords as a single string.
 */
export default function KeywordsField(obj = {}) {
	let self = <ui-keywords/>
	Object.defineProperties(self, Object.getOwnPropertyDescriptors(KeywordsField.prototype))

	let parent, nextSibling

	self.input = <span class='keywords' contentEditable/>

	self.hiddenInput = <input type='hidden'/>
	self.appendChild(self.hiddenInput)

	if (obj instanceof HTMLInputElement) {
		if (obj.type != 'text') {
			throw new Error(`Input element is not of type 'text'.`)
		}

		parent = obj.parentNode
		nextSibling = obj.nextElementSibling
		parent.removeChild(obj)

		self.input.textContent = obj.value
	}
	
	self.hiddenInput.name = obj.name
	self.appendChild(self.input)

	// Private functions.
	const keywordClicked = (event) => {
		self.removeChild(event.target)
	}

	const createKeywordElements = (keywords) => {
		const allKeywords = self.keywords

		while (keywords.length > 0) {
			let keyword = keywords.shift().trim()
			if (keyword && !allKeywords.includes(keyword)) {
				let element =
					<div class='keyword'
						onClick={keywordClicked}>
						{ keyword }
					</div>
				self.insertBefore(element, self.input)
			}
		}

		self.input.textContent = ''
		self.hiddenInput.value = self.keywords.join(' ')
	}

	const checkForKeywords = (force = false) => {
		let keywords = self.input.textContent.split(/\s/g)
		const minimum = force ? 0 : 1
		if (keywords.length > minimum) {
			createKeywordElements(keywords)
		}
	}

	// Events.
	self.input.addEventListener('blur', (event) => checkForKeywords(true))

	self.input.addEventListener('input', (event) => {
		checkForKeywords(false)
		dispatchEvent(self, 'change')
	})

	self.input.addEventListener('keydown', (event) => {
		const key = event.key || event.keyCode || event.which

		switch (key) {
			case 0x0D: case 'Enter': {
				event.preventDefault()
				checkForKeywords(true)
				break
			}
			case 0x08: case 'Backspace': {
				if (!self.input.textContent.trim()) {
					let lastKeyword = self.children[self.childElementCount - 2]
					if (lastKeyword && lastKeyword.classList.contains('keyword')) {
						event.preventDefault()
						
						self.removeChild(lastKeyword)
						self.input.textContent = lastKeyword.textContent.substring(0, lastKeyword.textContent.length)

						// Set the cursor to the end of the `input`.
						let range = document.createRange()
						let selection = getSelection()
						range.setStart(self.input.childNodes[0], self.input.textContent.length)
						range.collapse(true)
						selection.removeAllRanges()
						selection.addRange(range)
					}
				}
				break
			}
		}
	})

	self.addEventListener('click', (event) => self.input.focus())

	// Replace the DOM element.
	if (parent) {
		if (nextSibling) {
			parent.insertBefore(self, nextSibling)
		}
		else {
			parent.appendChild(self)
		}
	}

	checkForKeywords(true)

	return self
}

KeywordsField.prototype = {
	get keywords() {
		return [...this.children]
			.filter(el => el.classList.contains('keyword'))
			.map(el => el.textContent)
	},

	get value() {
		return this.input.textContent
	}
}