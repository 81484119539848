/**
 * Dispatch event function.
 * Wrapper around the stupid Internet Explorer bullcrap.
 */
export function dispatchEvent(obj, eventString) {
	let event = null

	if (typeof Event == 'function') {
		event = new Event(eventString)
	}
	else {
		event = document.createEvent('Event')
		event.initEvent(eventString, true, true)
	}

	obj.dispatchEvent(event)
}

export function elementInView(element) {
	const scrollY = window.scrollY || document.body.scrollTop
	const screenRange = [scrollY, innerHeight + scrollY]

	const offset = offsetOfElement(element)
	const elementRange = [offset.offsetTop, offset.offsetTop + element.offsetHeight]

	return elementRange[1] > screenRange[0]
		&& elementRange[0] < screenRange[1]
}

export function offsetOfElement(element) {
	let node = element
	let { offsetLeft, offsetTop } = node
	while ((node = node.parentNode) && 'offsetLeft' in node) {
		offsetLeft += node.offsetLeft
		offsetTop += node.offsetTop
	}
	return { offsetLeft, offsetTop }
}
