/**
 * © 2018 frzi
 * frzi.com | github.com/frzi
 */

/**
 * The function for JSX.
 * Make sure the `pragma` for the `transform-react-jsx` plugin is set accordingly.
 * Borrows the `ref` tag feature from React.
 *
 * - SVG done via the `svg:` namespace now.
 * - Fragments are supported!
 */
export default function jsx(tag, props, ...children) {
	let el = null

	function appendChildren(children, level = 0) {
		if (level < 5) {
			for (const child of children) {
				if (child == undefined) {
					continue
				}

				if (Array.isArray(child)) {
					appendChildren(child, level + 1)
				}
				else if ((child.dom || child) instanceof Node) {
					el.appendChild(child)
				}
				else if (typeof child !== 'boolean') {
					let node = document.createTextNode(child.toString())
					el.appendChild(node)
				}
			}
		}
	}

	if (typeof tag == 'string') {
		let isSVG = false
		if (tag == 'frag') {
			el = document.createDocumentFragment()
		}
		else {
			isSVG = svgTags.includes(tag)
			el = isSVG ? document.createElementNS('http://www.w3.org/2000/svg', tag) : document.createElement(tag)
		}

		// Properties (and attributes).
		if (props) {
			for (const [key, value] of Object.entries(props)) {
				if (value == undefined) {
					continue
				}

				// Style properties.
				if (key == 'style' && typeof value == 'object') {
					for (const property in value) {
						el.style[property] = value[property]
					}
				}
				// Inline `style` property.
				else if (key == 'style') {
					value.replace(/([^:\s]+)\s*:\s*([^;]+);*/gi, (g, p1, p2) => {
						const property = p1.split('-').reduce((prev, str) => {
							if (prev) {
								str = str[0].toUpperCase() + str.substr(1, str.length - 1)
							}
							return prev + str
						}, '')
						el.style[property] = p2
					})
				}
				// `className` can be either a string or an array.
				else if (key == 'className' || key == 'class') {
					el.className = Array.isArray(value) ? value.join(' ') : value.trim()
				}
				// React's `ref` feature.
				else if (key == 'ref') {
					value(el)
				}
				// Events.
				else if (/^on[A-Z]/.test(key)) {
					const eventName = key.substr(2, key.length - 2)
					el.addEventListener(eventName.toLowerCase(), value, true)
				}
				// Attributes for SVG.
				else if (isSVG && svgAttributes.includes(key)) {
					el.setAttribute(key, value)
				}
				// Direct properties.
				else if (value !== '') {
					el[key] = value
				}
			}
		}

		// Children.
		if (children.length) {
			appendChildren(children)
		}
	}
	else {
		if (tag === DocumentFragment) {
			el = document.createDocumentFragment()
		}
		else {
			el = new tag(props || {}, ...children)
		}

		if (!(el instanceof Node)) {
			throw new Error(`Instance of '${tag.name || tag}' is neither a Node nor has a '.dom' property.`)
		}

		// React's `ref` feature. Only for classes.
		if (el.dom && props && props.ref) {
			props.ref(el)
		}

		if (el instanceof DocumentFragment) {
			appendChildren(children)
		}
	}

	return el
}

const svgTags = ['svg', 'path', 'g']